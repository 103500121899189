import {withSentryErrorBoundary} from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary'
import {withLeanStyles} from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withLeanStyles'
import {ErrorPage} from '../../../commons/components/error-page'
import {getMembersPageSentryConfig} from '../../utils/sentry'
import App from '.'

export default {
  component: withSentryErrorBoundary(
    withLeanStyles(App, {
      cssPath: ['membersViewerWidget.min.css'],
      enableMemoization: true,
    }),
    getMembersPageSentryConfig('events-member-page-viewer'),
    withLeanStyles(ErrorPage, {cssPath: ['membersViewerWidget.min.css'], enableMemoization: true}),
  ),
}
